/* You can add global styles to this file, and also import other style files */

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
$grid-breakpoints: (
  xs: 0,
  sm: 360px,
  md: 768px,
  lg: 992px,
  xl: 1880px
);
$input-focus-shadow-color: #FFD100;
$input-btn-focus-box-shadow: 0 0 3px 1px $input-focus-shadow-color;
$input-focus-box-shadow: 0 0 3px 1px $input-focus-shadow-color;
$btn-focus-box-shadow: 0 0 3px 1px $input-focus-shadow-color;
$form-select-focus-box-shadow: 0 0 3px 1px $input-focus-shadow-color;
$btn-close-opacity: 1;
$btn-close-hover-opacity: 1;
$btn-close-focus-shadow: 0 0 0 0 transparent;

$errorColor: #C91913;

:root,
::before,
::after {
  --ups-icon-plus: "\e65b";
  --ups-icon-minus: "\e653";
  --ups-icon-chevron-left: "\e627";
  --ups-icon-chevron-right: "\e629";
  --ups-icon-x: "\e67b";
  --ups-icon-check: "\e622";
  --ups-icon-information: "\e645";
  --ups-icon-informationcircle: "\e646";
  --ups-icon-sort: "\e619";
  --ups-icon-exclamation: "\e638";
  --ups-icon-edit: "\e636";
  --ups-icon-search: "\e668";
  --ups-icon-export: "\e63a";
  --ups-icon-download: "\e634";
  --ups-icon-swap-horiz: "\e097";

  --error-color: #C91913;
}

@import "../node_modules/bootstrap/scss/bootstrap";

@font-face {
  font-family: 'upsicons';
  src: url('/assets/upsicons_v4.woff') format('woff'), url('/assets/upsicons_v4.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/Roboto-Regular.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/Roboto-Italic.ttf") format('truetype');
  font-weight: normal;
  font-style: italic
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/Roboto-Bold.ttf") format('truetype');
  font-weight: bold;
  font-style: normal
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/Roboto-Medium.woff") format("woff"), url("./assets/Roboto-Medium.woff2") format("woff2");
  font-style: normal;
  font-weight: 500;
  font-display: swap
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/Roboto-BoldItalic.ttf") format('truetype');
  font-weight: bold;
  font-style: italic
}

$errorColor: #C91913;

.preserve-white-space {
  white-space: pre;
}

select:disabled,
input:read-only,
button:disabled {
  background-color: #EDEDED !important;
  border: 1px solid #979797 !important;
  opacity: 1 !important;


  i:before {
    color: #6e6e6e !important;
  }
}

button:disabled{
  color: #979797 !important;;
}

.error .form-control,
.form-control.error,
.error .form-select,
.error .form-select .custom-trailer-select,
.error .input-group-text button {
  border: 1px solid $errorColor !important;
}

.warning .form-control,
.form-control.warning,
.warning .form-select .custom-trailer-select,
.warning .input-group-text button {
  border: 1px solid #F68B20 !important;
}

.warning .input-group .validation-icon:before {
  font-family: upsicons, fantasy !important;
  font-style: normal;
  content: '\E638' !important;
  color: #F68B20;
  position: absolute;
  top: 2px;
  right: 14px;
  height: 18px;
  width: 18px;
  padding: 8px;
}

.text-danger {
  color: $errorColor !important;
}

.text-success {
  color: #6e7e1b;
}

.text-warning {
  color: #F68B20 !important;
}

select:disabled {
  .ups-select-icon:after {
    color: #6e6e6e !important;
  }
}

a.link {
  cursor: pointer !important;
  color: #426EA9 !important;

  .icon {
    margin-right: 0.3em;
  }

  span {
    text-decoration: underline !important;
  }
}


.form-control {
  border: 1px solid #979797;
}

.round-button {
  border-radius: 1.5em;
}

.ups-table {
  width: 100%;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;

  th,
  td {
    padding: 0.8em;
    border-right: 1px solid #000;

    &:last-child {
      border-right: none;
    }
  }

  th {
    font-weight: bold;
  }

  tr {
    border-bottom: 1px solid #000;

    &:nth-child(odd) {
      background-color: #ececec;
    }
  }

  td a {
    text-decoration: underline;
    color: #404040;
    cursor: pointer;
  }
}

.icon {
  font-style: normal !important;
  padding: 0;
  margin: 0;
  font-size: 16px;
}

.icon-btn {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

html,
body,
app-root {
  height: 100% !important;
  overflow-x: hidden;
}

.main {
  flex: 1 0 auto;
  padding-top: 25px;

  &.on-property {
    padding-top: 0;
  }
}

/* Provide sufficient contrast against white background */
body {
  display: flex;
  flex-direction: column;
  font-family: "Roboto", Tahoma, Helvetica, sans-serif;
  font-size: 14px;
}

a {
  color: #0366d6;
  text-decoration: none;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.separator,
.seperator-after,
.seperator-before {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000;
}

/*  .separator::before, .seperator-before::before {
    margin-right: .25em;
  }

  .separator::after, .seperator-after::after {
    margin-left: .25em;
  }
*/
.seperator-after::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000;
}

.seperator-after::before {
  content: '';
  flex: 1;
}

.seperator-before::after {
  content: '';
  flex: 1;
}

.seperator-before::before {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000;
}


.bg-green {
  background-color: #02857E;
}

.bg-green-badge {
  background-color: #02857E;
  width: 22px;
  height: 20px;
  font-size: 12px;
}

.bg-orange {
  background-color: #F88C00;
  width: 28px;
  height: 28px;
  line-height: 28px;
  vertical-align: middle;
}

.bg-gray {
  background-color: #E8E8E6;
  border: solid 1px black;
  width: 22px;
  height: 20px;
  font-size: 12px;
  margin-top: 0;
}

@media (min-width: 768px) {
  .container-1 {
    margin-right: 4px;
    margin-left: 24px;
  }
}

@media (min-width: 992px) {
  .container-1 {
    margin: 0px 22px;
  }
}

@media (min-width: 1880px) {
  .container-1 {
    margin: 0px 152px;
  }
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (min-width: 992px) {

  .request-input-date {
    width: 377px;
    min-width: 377px;
    max-width: 377px;
  }

  .request-input-short {
    width: 377px;
    min-width: auto;
    max-width: auto;
    position: relative;
  }

  .request-input-long {
    width: 786px;
    min-width: auto;
    max-width: auto;
    position: relative;
  }
}


.request-input-short {
  ups-select.error::after {
    font-family: 'upsicons', fantasy !important;
    content: '\e638' !important;
    color: $errorColor;
    position: absolute;
    bottom: .4rem;
    right: 2.5rem;
    font-size: 16px;
  }

  .trailer-info-text.error::after {
    position: absolute;
    font-family: upsicons, fantasy !important;
    content: '\e638' !important;
    color: $errorColor;
    bottom: .5rem;
    right: 1.5rem;
    font-size: 16px;
  }

  .trailer-info-text.error.has-error-text::after {
    position: absolute;
    font-family: upsicons, fantasy !important;
    content: '\e638' !important;
    color: $errorColor;
    bottom: 1.75rem;
    right: 1.5rem;
    font-size: 16px;
  }
}

.request-input-long .request-reason-text.error::after {
  position: absolute;
  font-family: upsicons, fantasy !important;
  content: '\e638' !important;
  color: $errorColor;
  bottom: 2.5rem;
  right: 1.5rem;
  font-size: 16px;
}

.user-field {
  .user-field-input.error::after {
    font-family: 'upsicons', fantasy !important;
    content: '\e638' !important;
    color: $errorColor;
    font-size: 16px;
    position: absolute;
    top: 1.8rem;
    left: 20rem;
  }

  .user-field-select.error::after {
    font-family: 'upsicons', fantasy !important;
    content: '\e638' !important;
    color: $errorColor;
    font-size: 16px;
    position: absolute;
    bottom: 1.7rem;
    left: 18.2rem;
  }
}

/* Custom modal styling */
.modal-open .modal {
  z-index: 1056;
}

.modal-dialog-centered {
  padding-left: 5px;
  padding-right: 5px;
}

.modal-full-page {
  min-height: 100vh !important;
  height: 100vh;
  min-width: 100vw !important;
  width: 100vw;
  margin: 0;

  .modal-content {
    min-height: 100%;
    height: 100%;
    max-height: fit-content;
    min-width: 100%;
    width: 100%;
    border: 0;
    overflow-y: auto;

    full-page-modal {
      min-height: 100%;
      max-height: fit-content;
    }
  }
}

.modal-cancel-on-deactivator {
  width: 100%;

  @media screen and (min-width: 992px) {
    max-width: 900px !important;
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    max-width: 724px !important;
  }

  @media screen and (max-width: 767px) {
    max-width: 318px !important;

    .french {
      .cancel {
        .modal-title {
          line-height: 1;
        }
      }
    }
  }

  .modal-content {
    height: 100% !important;

    @media screen and (min-width: 768px) {
      max-height: 177px !important;
    }

    @media screen and (max-width: 767px) {
      max-height: 194px !important;
    }
  }
}

.modal-custom-name {
  .modal-content {
    min-width: 360px;
    min-height: 332px;
    max-height: fit-content;
    border: none;
  }
}

.modal-invalid-import-entries {
  .modal-content {
    min-width: 942px;
    max-width: fit-content;
    min-height: fit-content;
    max-height: 561px;
    border: none;
  }

  // wasn't centered horizontally by default?
  &.modal-dialog-centered {
    justify-content: center;
  }
}

.modal-export-comments {
  min-width: 661px;
  max-width: min-content;

  .modal-content {
    min-width: 661px;
    max-width: fit-content;
    min-height: 272px;
    max-height: fit-content;
  }

  &.modal-dialog-centered {
    justify-content: center;
  }
}

.delivery-type-modal {
  min-width: 448px;
}

.input-group-text {
  border: none;
  padding: 0;

  input {
    border-right: none;
  }
}

@media (min-width: 560px) {
  .default-responsible-site-info-en {
    min-width: 553px;
  }
}

@media (min-width: 768px) {
  .default-responsible-site-info-fr {
    min-width: 760px;
  }
}


/* ups icon styles */

.ups-icon:before {
  font-family: 'upsicons', fantasy !important;
}

.ups-icon-x:before {
  content: "\e67b";
}

.ups-icon-x-circle:before {
  content: "\e67c";
}

.ups-icon-check:before {
  content: "\e622";
}

.ups-icon-chevron-left:before {
  content: "\e627";
}

.ups-icon-chevron-right:before {
  content: "\e629";
}

.ups-icon-plus:before {
  content: "\e65b";
}

.ups-icon-plus-circle:before {
  content: "\e65c";
}

.ups-icon-download:before {
  content: "\e634" !important;
}

.ups-icon-swap-horiz:before {
  content: url("/assets/swap-horiz.svg");
}

.ups-icon-trash-can:before {
  content: url("/assets/trash-can.svg");
}


.ups-icon-exclamation:before {
  content: "\e638";
}

.ups-icon-menu:before {
  content: "\e652";
}

.ups-icon-chevron-up:before {
  content: "\e62b";
}

.ups-icon-chevron-down:before {
  content: "\e626";
}

.ups-icon-open-in-new:before {
  content: url("/assets/open-in-new.svg");
}

.ups-icon-informationcircle:before {
  content: "\e646";
}

button.btn.border-dark.rounded-0.bg-green {
  background-color: #00857D;
}

.modal-setup-incomplete {
  width: 553px;

  .modal-content {
    width: 553px;
    height: auto;
  }
}

h2, h4 {
  font-weight: 400;
}

.pl-0 {
  padding-left: 0;
}